.contactPage{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.contactCard{
    margin-top: 100px;
}

.intl-tel-input {width: 1000px;}

#contact{
  width:1000px;
}

#valid-msg{
  color:green;
}

#invalid-msg{
  color: red;
}

* {
    box-sizing: border-box;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  
  input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    width:100px;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  .container {
      display: flex;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }
  
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }
  
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
  }
  
  .row{
      display: flex;
      flex-direction: row;
      width: 500px;
  }
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 700px) {
    .col-25, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
    .col-75{
      float:right;
    }
    .contactPage{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
  }
  .contactCard{
    margin-top: 10px;
    margin-left: 10px;
  }
  .row{
    width: 300px;
}
  .enquiryForm{
    margin-left: 10px;
    margin-right: 10px;
  }
  }
  