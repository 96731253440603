.carouselContainerProduct{
    margin-left: 200px;
    margin-right: 200px;
}

.carousel .slide{ background: #e6f2ff!important; }



.carousel .slide .legend{
    bottom:20px;
    font-size: 14px;
}

.carousel .thumb img {
    width: 100% !important;
    height: 60px !important;
}

.carousel .control-dots .dot{
    background: black;
}

.carousel .carousel-status{
    font-size: 15px;
    color: black;
}

.carousel .slide img {
    max-height: 300px; 
    width: auto;
}

.productTitle{
    text-align: center;
}
@media all and (max-width: 700px){
.carouselContainerProduct{
    margin-left: 20px;
    margin-right: 20px;
}
.carousel .thumb img {
    width: 100% !important;
    height: 50px !important;
}
}