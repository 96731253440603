.parent {
    display: flex;
    flex-direction: column;
    font-family: Helvetica,Arial, sans-serif;
}

.grow {
flex: 1 0 auto;
}

@media all and (max-width: 700px) {
    .parent {
        width:100%;
    }

}
