.carouselContainer{
    margin-left: 200px;
    margin-right: 200px;
}

.carousel .slide{ background: white; }

.carousel .slide img {
    margin-top: 30px;
    max-height: 300px; 
    width: auto;
}

.brandTitle{
    text-align: center;
}

@media all and (max-width: 700px){
    .carouselContainer{
        margin-left: 20px;
        margin-right: 20px;
    }
    .brand .carousel .slide img {
        margin-top: 20px;
        max-height: 70px; 
        width: auto;
        margin-bottom: 20px;

    }
}