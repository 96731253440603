.brandCards{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.brandCardPage{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.brandCardBox{
    width: 220px;
    border: 10px solid gray;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    margin-left: 30px;
    margin-right: 15px;
}

.brandCardBoxTemp{
    width: 220px;
    border: 10px solid white;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    margin-left: 30px;
    margin-right: 15px;
}

#brandCardImg{
    margin: 10px 10px 10px 10px;
    border: 1px solid black;
    width: auto;
    height: 100px;
}

.brandPageTitle{
    text-align: center;
}

@media all and (max-width: 700px){
    .brandCards{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .brandCardPage{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 120px;
    }
    .brandCardBox{
        width: 120px;
        height: 100px;
        border: 4px solid gray;
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        text-align: center;
        margin-left: 0px;
        margin-right: 10px;
    }
    #brandCardImg{
        margin: 4px 4px 4px 4px;
        border: 0.5px solid black;
        width: 100px;
        height: auto;
    }
}