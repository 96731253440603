* {
    box-sizing: border-box;
  }

  input[type=text], select{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }

  .searchButton{
    background-color: #4CAF50;
    color: white;
    margin-left: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    height: 40px;
    width: 100px;
  }
  
  .searchButton:hover {
    background-color: #45a049;
  }

  .searchBar{
      display: flex;
      flex-direction: row;
  }

  .selectSearch{
    width: 300px;
    border: none;
    border-radius: 4px;
  }

@media all and (max-width: 830px) {
  .selectSearch{
    width: 200px;
  }
  .searchButton{
    margin-left: 5px;
    border-radius: 4px;
    height: 40px;
    width: 50px;
  }
}