.flex-container1{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color:  #F7F7F7;
    height: 25vh;
    padding-left: 3%;/*5vh;*/  
    width:auto;
}
.LogoImg{
    height: 20vh;
}
.image{
    align-self: center;

}
.flex-container2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size:2.2vh;
}

.flex-container3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size:2.2vh;
}

.L1{
    color: #0E157C;
    font-size: 5vh;
    font-weight: normal;
    
}
.L2{
     color: black;
     font-size: 3vh;
     font-weight: bold;   
}

@media all and (max-width: 830px) {
    .LogoImg{
        height: 10vh;
    }
    .L1{
        font-size: 3vh;
    }
    .L2{
        font-size: 2vh;
    }
    .flex-container1{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color:  #F7F7F7;
        height: 25vh;
        padding-left: 3%;/*5vh;*/  
        width:auto;
    }
    .flex-container3{
        display: flex;
        flex-direction: column;
    }
}