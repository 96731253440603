.categoryBox{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 320px;
    padding: 10px;
    border: 2px #F7F7F7;
    background-color: #F7F7F7;
    margin: 0;
}

.categoryFlex{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
}

Button{
    width: 320px;
    height: 32px;
}

.CategoryTitle{
    text-align: center;
}

.productCards{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
}

.productCardBox{
    width: 220px;
    border: 10px solid gray;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    margin-left: 30px;
    margin-right: 15px;
}

#cardImg{
    margin-top: 10px;
    border: 1px solid black;
    margin-left: 10px;
    margin-right: 10px;
    width: 180px;
    height: 180px;
}

.productCategoryTitle{
    margin-left: 30px;
}

@media all and (max-width: 700px) {
    .categoryFlex{
        display:flex;
        flex-direction: column;
        justify-content: space-around;
    }
}