.topnav {
    overflow: hidden;
    background-color: #46C6E0;
  }
  
  .topnav a {
    float: left;
    color: white;
    padding: 2.24vh 2.56vh;
    text-decoration: none;
    font-size: 3vh;
    border-bottom: 3px solid transparent;
  }
  
  .topnav a:hover {
    color: black;
    border-bottom: 3px solid blue;
  }

  
  .topnav a.active {
    color: white;
    border-bottom: 3px solid blue;
  }

  .dropdownMenu{
    position: absolute;
    overflow: hidden;
    width: auto;
    display:flex;
    background-color: #46C6E0;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 14vh;
    z-index: 100;
    height: 230px;
  }

  .dropdownMenu a {
    float: left;
    color: white;
    text-decoration: none;
    font-size: 3vh;
  }

  .dropdownMenu a:hover {
    color: black;
  }

  .dropdownSection{
    margin-top: 5px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

@media all and (max-width: 700px){
  .topnav a {
    padding: 1.5vh 1.75vh;
    font-size: 1.75vh;
  }
  .dropdownMenu a {
    font-size: 1.75vh;
  }
  .dropdownMenu{
    margin-left: 8vh;
    margin-right: 0.5vh;
  }
  .dropdownSection{
    margin-top: 5px;
    margin-left: 10px;
    justify-content: space-evenly;
  }
}